export interface Service {
    Name: string
    ID: number
    IsEnabled
    IsLabService
    ShowInsurance
    ShowFileUpload
    EmailReportTo?
    Tests
    HtmlTemplate:string
    ImportantInformation:string
    InformedConsent: string
    Summary:string
    ReleaseOfInformation:string
    ROIConsentAuth:string
    CreatedBy?: string
    CreatedDate: Date
    LastUpdatedBy?: string
    LastUpdatedDate: Date
    CustomFieldData?
    AllowMultipleTests?
    ExportParseURL?
    PlatingDirection
    WellplateSettings
    ProductID?
    InternalIdentifierSettings
    AdditionalInformation
    EnablePayments
    PricingIDs 
}

export const BarcodeTypes = [{label:'1D', value:'1D'},{label:'2D', value:'2D'}];

export const productionEssentialAndComprehensiveServiceIDs = [6,7,8,9];
export const developmentEssentialAndComprehensiveServiceIDs = [47,54,56];
export const demoEssentialAndComprehensiveServiceIDs = [6,7,8,9]; // demo Services were syned from production

export const productionEssentialNonUpgradableServiceIDs = [6,7];
export const productionEssentialUpgradableServiceIDs = [9];

export const devEssentialNonUpgradableServiceIDs = [47];
export const devEssentialUpgradableServiceIDs = [56];

export const demoEssentialNonUpgradeableServiceIDs = [6,7]; // demo Services were syned from production
export const demoEssentialUpgradeableServiceIDs = [9]

export const productionComprehensiveServiceIDs = [8];
export const devComprehensiveServiceIDs = [54];
export const demoComprehensiveServiceIDs = [8]; // demo Services were syned from production

export interface InternalIdentifierSettings {
    ID: number
    StartingValue: string
    BarcodeType: string
}